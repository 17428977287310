<template>
  <div class="brandMessage-container">
        <div class="header2">
            <van-nav-bar title="收款信息" left-text="返回" left-arrow @click-left="onClickLeft">
                <template #right>
                    <span @click="onClickRight" v-if="merchantBankInfo.accountType != undefined">编辑</span>
                    <span @click="addCollection" v-else>创建</span>
                </template>
            </van-nav-bar>
        </div>
        <div v-if="showEmpty">
            <van-empty image="search" description="哎呀!  您还没有收款信息..." />
        </div>
        <div class="productDescribe" v-if="merchantBankInfo.accountType == 1">
          <van-cell-group>
            <van-cell title="收款账户类型:" class='myCell' :value="merchantBankInfo.accountType == 1 ? '公司公户' : '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="账户名称:" class='myCell' :value="merchantBankInfo.receivingUnitName || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="收款账号:" class='myCell' :value="merchantBankInfo.receivingUnitAccount || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="收款开户银行:" class='myCell' :value="merchantBankInfo.receivingUnitOpenBankName || '-'" />
           </van-cell-group>
          <van-cell-group>
              <van-cell title="开户许可证:" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.accountCertification)"
                          :src="merchantBankInfo.accountCertification"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
      </div>
        <div class="productDescribe" v-if="merchantBankInfo.accountType == 2">
          <van-cell-group>
            <van-cell title="收款账户类型:" class='myCell' :value="merchantBankInfo.accountType == 2 ? '法人账户':'-'"/>
           </van-cell-group>
          <van-cell-group>
            <van-cell title="开户人姓名:" class='myCell' :value="merchantBankInfo.receivingUnitName || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="银行卡卡号:" class='myCell' :value="merchantBankInfo.receivingUnitAccount || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="开户银行全称:" class='myCell' :value="merchantBankInfo.receivingUnitOpenBankName || '-'" />
           </van-cell-group>
          <van-cell-group>
              <van-cell title="法人身份证(正面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                     lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.idCardFront)"
                          :src="merchantBankInfo.idCardFront"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="法人身份证(反面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.idCardBack)"
                          :src="merchantBankInfo.idCardBack"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="银行卡:" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.bankCardPic)"
                          :src="merchantBankInfo.bankCardPic"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="开户银行截图:" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.bankPic)"
                          :src="merchantBankInfo.bankPic"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
        </div>
        <div class="productDescribe" v-if="merchantBankInfo.accountType == 3">
          <van-cell-group>
            <van-cell title="收款账户类型:" class='myCell' :value="merchantBankInfo.accountType == 3? '其它账户':'-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="开户人姓名:" class='myCell' :value="merchantBankInfo.receivingUnitName || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="收款人身份证号:" class='myCell' :value="merchantBankInfo.idNo || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="银行卡卡号:" class='myCell' :value="merchantBankInfo.receivingUnitAccount || '-'" />
           </van-cell-group>
          <van-cell-group>
            <van-cell title="开户银行全称:" class='myCell' :value="merchantBankInfo.receivingUnitOpenBankName || '-'" />
           </van-cell-group>
          <van-cell-group>
              <van-cell title="法人身份证(正面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.idCardFront)"
                          :src="merchantBankInfo.idCardFront"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="法人身份证(反面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                      lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.idCardBack)"
                          :src="merchantBankInfo.idCardBack"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="银行卡:" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                     lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.bankCardPic)"
                          :src="merchantBankInfo.bankCardPic"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="开户银行截图:" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                     lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.bankPic)"
                          :src="merchantBankInfo.bankPic"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="收款人身份证(正面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                     lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.receiverCardFront)"
                          :src="merchantBankInfo.receiverCardFront"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
          <van-cell-group>
              <van-cell title="收款人身份证(反面):" class='myCell' value="">
              <van-swipe :loop="false"> 
              <van-swipe-item>
                  <van-image
                     lazy-load
                      width="70"
                      height="70"
                          @click="showImg(merchantBankInfo.receiverCardBack)"
                          :src="merchantBankInfo.receiverCardBack"
                      />
              </van-swipe-item>
              </van-swipe>
              </van-cell>
          </van-cell-group>
      </div>
  </div>
</template>

<script>
import {ImagePreview} from 'vant'
import { Toast } from 'vant';
export default {
    data(){
        return{
            showEmpty: false,
            merchantBankInfo: [],
            imgList: [],
        }
    },
    created(){
        Toast.loading({
            message: '加载中...',
            overlay: true,
            duration: 200,
            forbidClick: true,
        });
        setTimeout(()=>{
            this.getMerchantBankInfo();
        },100)
        
    },
    methods:{
        showImg(imgUrl){
            this.imgList.push(imgUrl)
            ImagePreview(this.imgList)
            this.imgList = []
        },
        // 返回列表
        onClickLeft(){
            this.$router.push({
                path: '/mine',
                query: {}
             })
        },
        addCollection(){
            this.$router.push({
                path: '/addCollectionInfo',
                query: {}
             })
        },
        onClickRight(){
            this.$router.push({
                path: '/updateCollectionInfo',
                query: {}
             })
        },
        getmerchantList(){
            this.$http.ajax('/ffkj-main/merchant/baseInfo/'+localStorage.getItem('merchantId'),{
                pageEnum: 'QUERY_PAGE',
            },'get',{}).then(res=>{
                if( res.code == 0 ){
                    // 根据审核状态判断当前显示的数据
                    if(res.data.showEditBtn){
                        this.merchantList = res.data
                    }else{
                        this.getmerchantList1();
                    }
                }
                else{
                    Notify({ type: 'danger', message: res.message })
                }
                var timer = setTimeout(function(){
                    window.clearTimeout(timer)
                }.bind(this), 300)
            })
        },
        getmerchantList1(){
            this.$http.ajax('/ffkj-main/merchant/baseInfo/'+localStorage.getItem('merchantId'),{
                pageEnum: 'EDIT_PAGE',
            },'get',{}).then(res=>{
                if( res.code == 0 ){
                    this.merchantList = res.data
                }
                else{
                    Notify({ type: 'danger', message: res.message })
                }
                var timer = setTimeout(function(){
                    window.clearTimeout(timer)
                }.bind(this), 300)
            })
        },
        // 获取账户信息
        getMerchantBankInfo(){
            this.$http
            .ajax(
                '/ffkj-main/merchant/getMerchantBankInfo?merchantId='+localStorage.getItem('merchantId'),
                {},
                'get',
                {}
            )
            .then((res) => {
                if (res.code == 0) {
                    if(res.data.accountType == undefined){
                        this.showEmpty = true;
                    }else{
                        this.showEmpty = false;
                        this.merchantBankInfo = res.data;
                    }
                } else {
                    Notify({ type: 'danger', message: res.message })
                }
            });
        }
    }
}
</script>

<style scoped>
</style>